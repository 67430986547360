<template>
  <div>
    <form
      action="#"
      @submit.prevent="submitReview"
      novalidate
      v-if="!loadingForm"
    >
      <div class="inputs display--flex justify-content-center">
        <div class="col">
          <custom-input v-model="form.title" label="Имя*" name="title" />
          <custom-textarea
            name="description"
            label="Текст*"
            horizontal
            v-model="form.description"
            :maxLength="500"
            :height="100"
          />
          <custom-input
            type="date"
            label="Дата*"
            name="date"
            v-model="form.date"
          />
        </div>
        <div class="col">
          <custom-select2
            label="Экспертный"
            :options="expertsOptions"
            name="expert_id"
            v-model="form.expert_id"
          />
        </div>
      </div>
      <div class="btns mt-25 mb-10 display--flex justify-content-end">
        <button
          v-if="
            getRight('partnersList') && review && review.status !== 'approved'
          "
          type="button"
          @click="submitReview('approved')"
          class="btn btn-accent mr-10"
        >
          Одобрить
        </button>
        <button
          type="button"
          @click="submitReview(false)"
          class="btn btn-accent mr-10"
        >
          Сохранить
        </button>
        <button type="button" @click="router.go(-1)" class="btn btn-w">
          Назад
        </button>
      </div>
      <div class="form-preloader" v-if="transactionLoading">
        <preloader />
      </div>
    </form>
    <div class="table-preloader" v-else>
      <preloader />
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import { useStore } from "vuex";
import { reactive, ref, computed } from "@vue/reactivity";
import PreviewFileLoader from "@/components/Forms/Fields/PreviewFileLoader.vue";
import { onBeforeMount } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import standartError from "@/mixins/standartError";
import CustomRadio from "@/components/Forms/Fields/CustomRadio.vue";
import useErrors from "@/mixins/useErrors";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import CustomTextarea from "../../../components/Forms/Fields/CustomTextarea.vue";
import CityInput from "../../../components/Forms/Fields/CityInput.vue";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import MultipleImageInput from "../../../components/Forms/Fields/MultipleImageInput.vue";
import CustomSelect from "../../../components/Forms/Fields/CustomSelect.vue";
import Preloader from "../../../components/Technical/Preloader.vue";
import useCheckRights from "../../../mixins/useCheckRights";

export default {
  components: {
    CustomInput,
    PreviewFileLoader,
    CustomRadio,
    CustomSelect2,
    CustomCheckbox,
    CustomTextarea,
    CityInput,
    MultipleImageInput,
    CustomSelect,
    Preloader,
  },
  name: "review-form",
  setup() {
    const store = useStore(),
      router = useRouter(),
      route = useRoute(),
      { setErrors, clearCustomErrors } = useErrors();

    const review = computed(() => store.state.partners.review);
    const partners = computed(() => store.state.partners.partners);
    const experts = computed(() => store.state.partners.experts);
    const profile = computed(() => store.state.profile.profile);

    const loadingForm = computed(() => store.state.ui.loadingForm);
    const transactionLoading = computed(
      () => store.state.ui.transactionLoading
    );

    const { getRight } = useCheckRights();

    const expertsOptions = ref([]),
      partnersOptions = ref([]);

    const form = reactive({
      title: null,
      description: null,
      date: null,
      expert_id: null,
    });

    onBeforeMount(() => {
      store.dispatch("partners/getExperts").then(() => {
        expertsOptions.value = experts.value.map((expert) => ({
          id: expert.id,
          text: expert.title,
        }));
      });
      if (route.params.id) {
        store.commit("ui/setLoadingForm", true);
        store
          .dispatch("partners/getReview", route.params.id)
          .then(() => {
            form.title = review.value.title;
            form.description = review.value.description;
            form.date = review.value.date;
            form.expert_id = review.value.expert.id;
          })
          .then(() => store.commit("ui/setLoadingForm", false));
        return;
      }
    });

    const submitReview = (approved) => {
      store.commit("ui/setTransactionLoading", true);
      if (route.path === "/review/create") {
        store
          .dispatch("partners/createReview", {
            ...form,
          })
          .then(() => {
            router.push("/reviews");
          })
          .catch(standartError(setErrors, clearCustomErrors))
          .finally(() => store.commit("ui/setTransactionLoading", false));
        return;
      }
      store
        .dispatch("partners/updateReview", {
          id: route.params.id,
          review: {
            ...review.value,
            ...form,
            status: approved || review.value.status,
          },
        })
        .catch(standartError(setErrors, clearCustomErrors))
        .finally(() => store.commit("ui/setTransactionLoading", false));
    };

    return {
      form,
      submitReview,
      router,
      route,
      partnersOptions,
      expertsOptions,
      profile,
      getRight,
      review,
      loadingForm,
      transactionLoading,
    };
  },
};
</script>

<style lang="scss">
.editor-block {
  .ql-editor {
    font-size: 15px;
    height: 200px;
  }
}
</style>
<style lang="scss" scoped>
.create-partner {
  border-bottom: 1px solid var(--cream-color);
}

.format-checkboxes {
  padding-left: 10px;
  margin-top: 15px;
  .format-title {
    flex-basis: 25%;
  }

  .checkboxes {
    gap: 28px;
  }

  @media (max-width: 973px) {
    padding-left: 0;
    flex-direction: column;
    align-items: flex-start;
    .checkboxes {
      padding-left: 7px;
    }
  }

  @media (max-width: 816px) {
    .checkboxes {
      flex-direction: column;
      gap: 4px;
      margin-top: 10px;

      .form-group {
        margin: 5px -5px;
      }
    }
  }
}

.block {
  padding-left: 10px;
  .block-title {
    font-size: 15px;
    font-weight: 400;
  }
  @media (max-width: 973px) {
    padding-left: 0;
  }
}

.editor-title {
  margin-bottom: 15px;
}

.col {
  flex: 0 1 50%;
  padding: 0 10px;
}

@media (max-width: 1500px) {
  .inputs,
  .create-partner {
    flex-direction: column;
  }
}

@media (min-width: 769px) {
  .form-group {
    margin: 25px -5px;
  }
}

@media (max-width: 425px) {
  .photo-area {
    margin: 0 !important;
  }
}
</style>